import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VlModalComponent } from './vl-modal.component';
import { VlIconModule } from '@components_shared/vl-icon/vl-icon.module';
import { VlFormGroupModule } from '@components_shared/vl-form-group/vl-form-group.module';
import { MatDatepickerModule } from '@angular/material/datepicker';

@NgModule({
	declarations: [VlModalComponent],
	imports: [CommonModule, VlIconModule, VlFormGroupModule],
	exports: [VlModalComponent],
})
export class VlModalModule {}
