import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	private vlToken: string = 'tokenAuth';
	constructor(private _storageSrc: LocalStorageService) {}

	setToken(token: string) {
		this._storageSrc.set(this.vlToken, token);
	}

	getToken(): string | null {
		return this._storageSrc.get(this.vlToken);
	}

	getInfo(): vlToken | null {
		try {
			const token = this.getToken();
			if (!token) throw {};

			const tokenHashed = token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/');
			const token_clean = decodeURIComponent(
				atob(tokenHashed)
					.split('')
					.map(function (c) {
						return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
					})
					.join('')
			);

			return JSON.parse(token_clean);
		} catch (error) {
			return null;
		}
	}

	havePermission(...permissions: any): boolean {
		try {
			let tokenInfo = this.getInfo();
			if (!tokenInfo) throw {};
			let matchs = permissions.map((permission: string) => (tokenInfo as vlToken).permissions.includes(permission));
			return matchs.every((match: boolean) => match === true);
		} catch (error) {
			return false;
		}
	}

	haveAnyPermission(...permissions: any): boolean {
		try {
			let tokenInfo = this.getInfo();
			if (!tokenInfo) throw {};
			let matchs = permissions.map((permission: string) => (tokenInfo as vlToken).permissions.includes(permission));
			return matchs.some((match: boolean) => match === true);
		} catch (error) {
			return false;
		}
	}

	clear() {
		this._storageSrc.remove(this.vlToken);
	}

	clearAll() {
		this._storageSrc.clear();
		const widItem = document.querySelector('videolink-wid');
		if (widItem) {
			widItem.remove();
		}
	}
}
