import { Injectable } from '@angular/core';
import { CrudResourceGenerator } from '@utils/crudResourceGenerator';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class MembershipsLiveService extends CrudResourceGenerator {
	public membership = new BehaviorSubject<any>({});
	public companyActives = new BehaviorSubject<any>({});

	constructor() {
		super();
		this.primaryRoute = '/v3/memberships';

		this.tapCustom.getResources = (response) => {
			// console.log({ response });
			this.membership.next(response?.data?.current ?? {});
			this.companyActives.next(response?.data?.companyActives ?? {});
		};
	}
}
