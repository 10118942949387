<div class="mainHeader">
	<ng-container *ngIf="screenWidth < 1025; else laptop">
		<button class="btnMenuNavigation" (click)="toggleMenuMobile($event)">
			<div class="line"></div>
			<div class="line"></div>
			<div class="line"></div>
		</button>
	</ng-container>

	<ng-template #laptop>
		<img class="logo" src="assets/logos/videolink.svg" />
	</ng-template>

	<button class="btnMenuProfile" (click)="canShowTooltipTour = false" [matMenuTriggerFor]="menu">
		{{ nameUser[0].toUpperCase() }}

		<div class="tout_tooltip" (click)="canShowTooltipTour = false" [@tooltipTour]="canShowTooltipTour ? 'show' : 'hidden'">
			<button class="tout_tooltip__close" (click)="hiddenTooltipTour($event)">
				<v-icon class="icon" name="close"></v-icon>
			</button>
			<v-icon class="tout_tooltip__icon" name="tutorial"></v-icon>
			<p class="tout_tooltip__text">{{ msgTooltipTour }}</p>
		</div>
	</button>
	<mat-menu #menu="matMenu" backdropClass="menuMainHeader">
		<div class="box_user">
			<span class="name" [matTooltip]="nameUser">{{ nameUser }}</span>
			<span class="rol">{{ roleName }}</span>
		</div>
		<button class="btn_menu_item" [routerLink]="['/dashboard/attentions']">
			<span class="name">Atención</span>
			<v-icon class="icon" name="atencion"></v-icon>
		</button>
		<button class="btn_menu_item" [routerLink]="['/dashboard/channels']">
			<span class="name">Canales</span>
			<v-icon class="icon" name="canal"></v-icon>
		</button>
		<button class="btn_menu_item" [routerLink]="['/dashboard/configurations']">
			<span class="name">Ajustes</span>
			<v-icon class="icon" name="ajuste"></v-icon>
		</button>
		<a class="btn_menu_item" [href]="agentUrl" target="_blank">
			<span class="name">Ir al AgentApp</span>
			<v-icon class="icon" name="agentApp"></v-icon>
		</a>
		<button class="btn_menu_item" (click)="openWidgetSupport()">
			<span class="name">Soporte</span>
			<v-icon class="icon" name="v-support"></v-icon>
		</button>
		<a class="btn_menu_item" [href]="conditionUrl" target="_blank">
			<span class="name">Términos y condiciones</span>
			<v-icon class="icon" name="conditions"></v-icon>
		</a>
		<!-- <button class="btn_menu_item tutorial" (click)="activateTour()">
			<span class="name">Tutorial</span>
			<v-icon class="icon" name="tutorial"></v-icon>
		</button> -->

		<button class="btn_menu_item logout" (click)="closeSession()">
			<span class="name">Cerrar sesión</span>
			<v-icon class="icon" name="logout"></v-icon>
		</button>
	</mat-menu>
</div>
