import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, interval, Observable, Subscription } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { environment } from 'src/environments/environment';
import { AuthService } from '../storage/auth.service';

@Injectable({
	providedIn: 'root',
})
export class WebSocketService {
	public _socket!: Socket<any>;
	pingInterva: Subscription = new Subscription();
	status: BehaviorSubject<'connect' | 'disconnect'> = new BehaviorSubject<'connect' | 'disconnect'>('disconnect');
	notified_connect: Boolean = Boolean(localStorage.getItem('notified_connect'));

	constructor(private auth: AuthService) {}

	init() {
		let id = localStorage.getItem('id') || false;
		this._socket = io(environment.wsServer, {
			withCredentials: true,
			timeout: 20000,
			query: {
				data: JSON.stringify({
					agent: false,
					role: 'supervisor',
					token: this.auth.getToken(),
					id,
				}),
			},
		});

		this._socket.connect();

		this._socket.on('connect', () => {
			this.status.next('connect');

			this._socket.on('id', (data: any) => {
				localStorage.setItem('id', data.id);
			});

			if (!this.notified_connect) {
				this.emit('connect_admin');
				localStorage.setItem('notified_connect', 'true');
				this.notified_connect = true;
			}

			/*Envio de ping para mantener comunicacion abierta, reduce los tiempos de reconexion */
			this.pingInterva = interval(5000).subscribe((data) => {
				this._socket.emit('ping');
			});
		});

		this._socket.on('disconnect', () => {
			this.status.next('disconnect');
			this.pingInterva.unsubscribe();
		});
	}

	listen(event: string): Observable<any> {
		return new Observable((sub) => {
			this._socket.on(event, (data: any) => {
				sub.next(data);
			});
		});
	}

	emit(event: string, data: any = {}): void {
		this._socket.emit(event, data);
	}

	async takeCall() {
		this.emit('atender');
		this.listen('');
	}

	public closeSocket() {
		this._socket.close();
		this._socket.disconnect();
	}
}
