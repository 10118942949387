import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { LoaderService } from '@services_shared/global/loader.service';
import { BehaviorSubject, filter, Subject, takeUntil } from 'rxjs';

@Component({
	selector: 'vl-loader',
	templateUrl: './vl-loader.component.html',
	styleUrls: ['./vl-loader.component.scss'],
})
export class VlLoaderComponent implements OnInit, OnDestroy {
	public isActive: boolean = false;
	private _unSuscribeAll: Subject<any> = new Subject();
	constructor(private _loaderSrc: LoaderService, private _router: Router) {}

	ngOnInit(): void {
		this._loaderSrc.loading.pipe(takeUntil(this._unSuscribeAll)).subscribe((status) => (this.isActive = status));

		this._router.events
			.pipe(
				filter(
					(event) =>
						event instanceof NavigationStart ||
						event instanceof NavigationEnd ||
						event instanceof NavigationCancel ||
						event instanceof NavigationError
				)
			)
			.subscribe((event) => {
				event instanceof NavigationStart ? this._loaderSrc.show('navigation') : this._loaderSrc.hidden('navigation');
			});
	}
	ngOnDestroy(): void {
		this._unSuscribeAll.next({});
		this._unSuscribeAll.complete();
	}
}
