<div
	[ngClass]="['vl-modal-overlay', isOpen ? 'open' : '']"
	[ngStyle]="{
		'--origin': config?.dialog?.style['--origin'] ?? 'center'
	}"
>
	<dialog
		#dialog
		open
		class="vl-modal-dialog"
		[ngStyle]="{
			'--maxWith': config?.dialog?.style['--maxWith'] ?? 'initial',
			'--maxHeigth': config?.dialog?.style['--maxHeigth'] ?? 'initial',
			'--minHeigth': config?.dialog?.style['--minHeigth'] ?? 'initial',
			'--with': config?.dialog?.style['--with'] ?? 'initial',
			'--heigth': config?.dialog?.style['--heigth'] ?? 'initial',
			'--bg': config?.dialog?.style['--bg'] ?? '#ffffff',
			'--border-r': config?.dialog?.style['--border-r'] ?? '6px',
			'--margin': config?.dialog?.style['--margin'] ?? '0 20px'
		}"
	>
		<header
			class="vl-modal-dialog__header"
			[ngStyle]="{
				'--padding': config?.header?.style['--padding'] ?? '12px',
				'--bg': config?.header?.style['--bg'] ?? '#ffffff',
				'--color': config?.header?.style['--color'] ?? '#000000',
				'--textAling': config?.header?.style['--textAling'] ?? 'center'
			}"
		>
			<h2 class="vl-modal-dialog__header__title">{{ config?.header?.title }}</h2>

			<button class="vl-modal-dialog__header__btnClose" type="button" (click)="hidden()">
				<svg viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M3.25317 3.06259C3.19924 3.06259 3.14538 3.08325 3.10408 3.12406C3.02195 3.20492 3.02195 3.33963 3.10408 3.42176L6.3788 6.69522C6.4605 6.77607 6.59436 6.77607 6.67649 6.69522C6.75819 6.61436 6.75819 6.47837 6.67649 6.39752L3.40177 3.12406C3.36095 3.08325 3.30703 3.06259 3.2531 3.06259H3.25317Z"
						fill="currentColor"
					/>
					<path
						d="M6.52887 3.064C6.47494 3.064 6.42108 3.08466 6.37977 3.12547L3.10505 6.39947C3.02293 6.48033 3.02293 6.61632 3.10505 6.69717C3.18718 6.77803 3.32062 6.77803 3.40275 6.69717L6.67747 3.42371C6.7596 3.34158 6.7596 3.20686 6.67747 3.12602C6.63665 3.08478 6.58322 3.064 6.52887 3.064Z"
						fill="currentColor"
					/>
					<path
						d="M4.89125 0.0690603C2.22109 0.0690603 0.0488281 2.24186 0.0488281 4.91148C0.0488281 7.58201 2.22127 9.75391 4.89125 9.75391C7.56123 9.75391 9.73367 7.58201 9.73367 4.91148C9.73367 2.24186 7.56123 0.0690603 4.89125 0.0690603ZM4.89125 9.33288C2.45317 9.33288 0.469853 7.34993 0.469853 4.91148C0.469853 2.47376 2.45317 0.490086 4.89125 0.490086C7.32934 0.490086 9.31265 2.47376 9.31265 4.91148C9.31265 7.34993 7.32934 9.33288 4.89125 9.33288Z"
						fill="currentColor"
					/>
				</svg>
			</button>
		</header>
		<div
			class="vl-modal-dialog__body"
			[ngStyle]="{
				'--padding': config?.body?.style['--padding'] ?? '12px 12px 0px',
				'--bg': config?.body?.style['--bg'] ?? '#ffffff'
			}"
		>
			<ng-container *ngFor="let element of config?.body?.content ?? []">
				<ng-container
					*ngIf="element.type === 'img'"
					[ngTemplateOutlet]="img"
					[ngTemplateOutletContext]="{ item: element.item }"
				></ng-container>
				<ng-container
					*ngIf="element.type === 'text'"
					[ngTemplateOutlet]="text"
					[ngTemplateOutletContext]="{ item: element.item }"
				></ng-container>
				<ng-container
					*ngIf="element.type === 'list'"
					[ngTemplateOutlet]="list"
					[ngTemplateOutletContext]="{ item: element.item }"
				></ng-container>
				<ng-container
					*ngIf="element.type === 'boxDeleteResource'"
					[ngTemplateOutlet]="boxDeleteResource"
					[ngTemplateOutletContext]="{ item: element.item }"
				></ng-container>
				<ng-container
					*ngIf="element.type === 'form'"
					[ngTemplateOutlet]="form"
					[ngTemplateOutletContext]="{ item: element.item }"
				></ng-container>
			</ng-container>
		</div>
		<footer
			class="vl-modal-dialog__footer"
			[ngStyle]="{
				'--padding': config?.footer?.style['--padding'] ?? '12px',
				'--justifyContent': config?.footer?.style['--justifyContent'] ?? 'center'
			}"
		>
			<button
				class="vl-modal-dialog__footer__btnCancel"
				type="button"
				*ngIf="config?.footer?.btnCancel?.available"
				[ngStyle]="{ 'flex-grow': config?.footer?.btnCancel?.strench ? '1' : '0' }"
				(click)="hidden()"
			>
				{{ config?.footer?.btnCancel?.text ?? 'Cancelar' }}
			</button>
			<button
				class="vl-modal-dialog__footer__btnReset"
				type="button"
				*ngIf="config?.footer?.btnReset?.available"
				[ngStyle]="{ 'flex-grow': config?.footer?.btnReset?.strench ? '1' : '0' }"
				(click)="resetForms(this.element)"
			>
				{{ config?.footer?.btnReset?.text ?? 'Restablecer' }}
			</button>
			<button
				class="vl-modal-dialog__footer__btnSuccess"
				type="button"
				*ngIf="config?.footer?.btnSucess?.available"
				[ngStyle]="{ 'flex-grow': config?.footer?.btnSucess?.strench ? '1' : '0' }"
				[disabled]="!validAllForm"
				(click)="hidden(this.element)"
			>
				{{ config?.footer?.btnSucess?.text ?? 'Continuar' }}
			</button>
		</footer>
	</dialog>
</div>

<!-- TEMP -->
<ng-template #img let-item="item">
	<img class="vl-modal-dialog__body__img" [ngStyle]="item.style ?? {}" [src]="item.value" [alt]="item.alt" />
</ng-template>
<ng-template #text let-item="item">
	<p class="vl-modal-dialog__body__p" [ngStyle]="item.style ?? {}">{{ item.value }}</p>
</ng-template>
<ng-template #list let-item="item">
	<ul class="vl-modal-dialog__body__list">
		<li class="vl-modal-dialog__body__list__item" *ngFor="let element of item.value">{{ element }}</li>
	</ul>
</ng-template>

<ng-template #boxDeleteResource let-item="item">
	<p class="vl-modal-dialog__body__boxDeleteResource">{{ item.value }}</p>
</ng-template>

<ng-template #form let-item="item">
	<div [formGroup]="item.value" [ngStyle]="item.style ?? {}">
		<ng-container *ngFor="let key of keys(item.configControls)">
			<vl-form-group-s1
				[label]="item.configControls[key].label"
				[type]="item.configControls[key].type"
				[checkMsg]="item.configControls[key].checkMsg"
				[formControlName]="key"
				[optionsoFSelect]="item.configControls[key].options ?? []"
				[min]="item.configControls[key].min ?? ''"
				[max]="item.configControls[key].max ?? ''"
				(filesOnChange)="item.value.controls[key].setValue($event?.fileRaw ?? null)"
				[placeholder]="item.configControls[key].placeholder"
				[optionDatalist]="item.configControls[key].optionDatalist ?? []"
				[extensions]="item.configControls[key].extensions ?? ['image/jpeg', 'image/png', 'application/pdf', '.xlsx']"
			></vl-form-group-s1>
		</ng-container>
	</div>
</ng-template>
