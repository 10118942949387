import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
// import { ToastrModule, ToastrService } from 'ngx-toastr';
// import { NgxLoadingModule } from 'ngx-loading';
import { LoginService } from 'src/app/services/backend/login.service';
import { WebSocketService } from 'src/app/services/backend/web-socket.service';
import { HeaderComponent } from './header/header.component';
import { MenuComponent } from './menu/menu.component';
import { SubMenuComponent } from './sub-menu/sub-menu.component';
// import { ContentModule } from '../content/content.module';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { ComponentsModule } from 'src/app/@components/components.module';
import { TourModule } from '@components/tour/tour.module';
import { SubMenuItemComponent } from './sub-menu/sub-menu-item/sub-menu-item.component';
import { WithThesePermissionModule } from '@directives/with-these-permission/with-these-permission.module';
@NgModule({
	declarations: [DashboardComponent, HeaderComponent, MenuComponent, SubMenuComponent, SubMenuItemComponent],
	imports: [
		CommonModule,
		DashboardRoutingModule,
		FormsModule,
		ReactiveFormsModule,
		MatFormFieldModule,
		MatIconModule,
		MatInputModule,
		MatCheckboxModule,
		MatTooltipModule,
		// ContentModule,
		MatSidenavModule,
		MatSelectModule,
		MatMenuModule,
		MatExpansionModule,
		MatListModule,
		ComponentsModule,
		// ToastrModule.forRoot(),
		// NgxLoadingModule.forRoot({}),
		TourModule,
		WithThesePermissionModule,
	],
	providers: [
		// ToastrService,
		LoginService,
	],
})
export class DashboardModule {}
