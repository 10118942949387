import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VlFormGroupS1Module } from './styles/vl-form-group-s1/vl-form-group-s1.module';
import { VlFormGroupS2Module } from './styles/vl-form-group-s2/vl-form-group-s2.module';

@NgModule({
	declarations: [],
	imports: [CommonModule, VlFormGroupS1Module, VlFormGroupS2Module],
	exports: [VlFormGroupS1Module, VlFormGroupS2Module],
})
export class VlFormGroupModule {}
