import { Injectable } from '@angular/core';
import { LocalstorageService } from './localstorage.service';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	tokenName: string = 'tokenAuth';
	constructor(private _storage: LocalstorageService) {}

	setToken(token: string) {
		this._storage.set(this.tokenName, token);
		this.storeSessionInfo(this.getInfo());
	}

	getToken() {
		return this._storage.get(this.tokenName);
	}

	getInfo() {
		const token = this._storage.get(this.tokenName);
		if (token) {
			const tokenHashed = token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/');
			const token_clean = decodeURIComponent(
				atob(tokenHashed)
					.split('')
					.map(function (c) {
						return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
					})
					.join('')
			);
			return JSON.parse(token_clean);
		}
	}

	clear() {
		this._storage.remove(this.tokenName);
	}

	clearAll() {
		this._storage.clear();
		const widItem = document.querySelector('videolink-wid');
		if (widItem) {
			// @ts-ignore
			widItem.style.display = 'none';
			widItem.setAttribute('data', '{}');
			// widItem.remove();
		}
	}

	decodeToken(token: string | undefined) {
		if (!token) return null;

		const tokenHashed = token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/');

		const token_clean = decodeURIComponent(
			atob(tokenHashed)
				.split('')
				.map(function (c) {
					return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
				})
				.join('')
		);

		return JSON.parse(token_clean);
	}

	storeSessionInfo(data: any) {}
}
