import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { FbsdkService } from '@services/backend/fbsdk.service';
import { MembershipsLiveService } from '@services_shared/live/memberships-live.service';
import { lastValueFrom } from 'rxjs';

export const metaResolver: ResolveFn<boolean> = (
	route,
	state,
	metaSrc: FbsdkService = inject(FbsdkService),
	membershipSrc: MembershipsLiveService = inject(MembershipsLiveService)
) => {
	//metaSrc.init();

	console.log('Se ejecuto esto');

	return lastValueFrom(membershipSrc.getResources()).finally(() => true);
};
